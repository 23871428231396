import React from 'react';

const PDFViewerComponent = ({ pdfUrl }) => {
  if (!pdfUrl) {
    return (
      <div className="pdf-error">
        <p>No PDF file available. Please contact an administrator to upload a PDF character sheet.</p>
      </div>
    );
  }

  return (
    <div className="pdf-viewer fullscreen">
      <iframe
        src={pdfUrl}
        width="100%"
        height="100%"
        title="Character Sheet PDF"
        className="pdf-iframe fullscreen"
      />
    </div>
  );
};

export default PDFViewerComponent; 