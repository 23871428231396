import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useAuth } from '../context/AuthContext';
import './AdminDashboard.css';

const AdminDashboard = () => {
  const { currentUser, logout } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [roleRequests, setRoleRequests] = useState([]);
  const [activeTab, setActiveTab] = useState('users');
  const [searchTerm, setSearchTerm] = useState('');
  const [confirmAction, setConfirmAction] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [sendingMessage, setSendingMessage] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [showMessageForm, setShowMessageForm] = useState(false);
  const [showHtmlGuide, setShowHtmlGuide] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [uploadingCharacterSheet, setUploadingCharacterSheet] = useState(false);
  const [characterSheetError, setCharacterSheetError] = useState('');
  const [characterSheetSuccess, setCharacterSheetSuccess] = useState('');
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryFolders, setGalleryFolders] = useState([]);
  const [galleryCategories, setGalleryCategories] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [isLoadingGallery, setIsLoadingGallery] = useState(false);
  const [galleryError, setGalleryError] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageZoomed, setImageZoomed] = useState(false);
  const [filteredImages, setFilteredImages] = useState([]);
  const [activeFilter, setActiveFilter] = useState(null);
  const [sortCriteria, setSortCriteria] = useState("name");
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [quickEditUser, setQuickEditUser] = useState(null);
  const [showQuickRoleEdit, setShowQuickRoleEdit] = useState(false);
  const [batchRole, setBatchRole] = useState("");
  const [showBatchRoleEdit, setShowBatchRoleEdit] = useState(false);
  const [editUserProfile, setEditUserProfile] = useState(false);
  const [userProfileData, setUserProfileData] = useState({
    displayName: '',
    email: ''
  });
  const [profileUpdateMessage, setProfileUpdateMessage] = useState({ type: '', message: '' });
  
  // Add these for message sending functionality
  const [showAdventurerMessageForm, setShowAdventurerMessageForm] = useState(false);
  const [adventurerMessage, setAdventurerMessage] = useState('');
  const [adventurerMessageSubject, setAdventurerMessageSubject] = useState('');
  const [sendingAdventurerMessage, setSendingAdventurerMessage] = useState(false);
  const [adventurerMessageSuccess, setAdventurerMessageSuccess] = useState('');
  const [adventurerMessageError, setAdventurerMessageError] = useState('');
  
  // Add these missing state variables to fix the errors
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  // State for specific user message
  const [userMessageText, setUserMessageText] = useState('');
  const [sendingUserMessage, setSendingUserMessage] = useState(false);
  const [userMessageError, setUserMessageError] = useState('');
  const [userMessageSuccess, setUserMessageSuccess] = useState('');
  const [showUserMessageForm, setShowUserMessageForm] = useState(false);
  // Add state variables for messages functionality
  const [userMessages, setUserMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [editMessage, setEditMessage] = useState(null);
  const [userMessageSubject, setUserMessageSubject] = useState('');
  
  // Add state for messages tab
  const [allMessages, setAllMessages] = useState([]);
  const [loadingAllMessages, setLoadingAllMessages] = useState(false);
  const [messageUsers, setMessageUsers] = useState([]);
  const [selectedMessageUser, setSelectedMessageUser] = useState(null);
  const [messageFilter, setMessageFilter] = useState('all'); // 'all', 'pinned'
  const [togglingPin, setTogglingPin] = useState(false);

  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';

  useEffect(() => {
    fetchUsers();
    fetchRoleRequests();
  }, []);

  useEffect(() => {
    if (activeTab === 'gallery' && galleryCategories.length === 0 && !isLoadingGallery) {
      fetchGalleryCategories();
    }
  }, [activeTab, galleryCategories.length, isLoadingGallery]);

  // Add effect to fetch message users when messages tab is active
  useEffect(() => {
    if (activeTab === 'messages') {
      fetchMessageUsers();
    }
  }, [activeTab === 'messages']);

  // Add key navigation for image modal
  useEffect(() => {
    if (!selectedImage) return;
    
    function handleKeyDown(e) {
      if (e.key === 'Escape') {
        handleCloseModal();
      } else if (e.key === 'ArrowRight') {
        handleNextImage();
      } else if (e.key === 'ArrowLeft') {
        handlePrevImage();
      } else if (e.key === ' ') {
        toggleImageZoom();
        e.preventDefault();
      }
    }
    
    window.addEventListener('keydown', handleKeyDown);
    
    // Clean up event listener on unmount or when selectedImage changes
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedImage, currentImageIndex]);

  const fetchUsers = async () => {
    setLoading(true);
    setError('');
    
    try {
      console.log('Fetching users from:', `${API_URL}/admin/users`);
      const response = await fetch(`${API_URL}/admin/users`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `Server responded with status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Users fetched successfully:', data);
      setUsers(data);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError(`Error fetching users: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const fetchRoleRequests = async () => {
    try {
      console.log('Fetching role requests from:', `${API_URL}/admin/role-requests`);
      const response = await fetch(`${API_URL}/admin/role-requests`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Error response:', errorData);
        return; // Don't set error state here to avoid overriding user fetch errors
      }
      
      const data = await response.json();
      console.log('Role requests fetched successfully:', data);
      setRoleRequests(data);
    } catch (err) {
      console.error('Error fetching role requests:', err);
      // Don't set error state here to avoid overriding user fetch errors
    }
  };

  const fetchNotifications = async (silent = false) => {
    try {
      if (!silent) setLoading(true);
      
      const response = await fetch(`${API_URL}/admin/notifications`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Error response:', errorData);
        return;
      }
      
      const data = await response.json();
      console.log('Notifications fetched successfully:', data);
      
      // Count unread notifications
      const unread = data.filter(notification => !notification.read).length;
      setUnreadNotifications(unread);
      
      setNotifications(data);
    } catch (err) {
      console.error('Error fetching notifications:', err);
    } finally {
      if (!silent) setLoading(false);
    }
  };

  const markNotificationAsRead = async (notificationId) => {
    console.log('Notification marking as read is not implemented');
  };

  const handleUserSelect = (user) => {
    if (selectedUser && selectedUser.id === user.id) {
      // If clicking the same user, toggle the details closed
      setSelectedUser(null);
      setShowUserDetails(false);
    } else {
      // If clicking a different user, show that user's details
      setSelectedUser(user);
      setShowUserDetails(true);
      // Initialize profile edit data when selecting a user
      setUserProfileData({
        displayName: user.displayName || '',
        email: user.email || ''
      });
      // Fetch user messages
      fetchUserMessages(user.id);
    }
    setShowMessageForm(false);
    setShowUserMessageForm(false);
    setUserMessageText('');
    setUserMessageSubject('');
    setUserMessageError('');
    setUserMessageSuccess('');
    setEditMessage(null);
    setEditUserProfile(false);
    setCharacterSheetError('');
    setCharacterSheetSuccess('');
    setProfileUpdateMessage({ type: '', message: '' });
  };

  const handleBackToList = () => {
    setShowUserDetails(false);
    setShowMessageForm(false);
    setShowUserMessageForm(false);
    setUserMessageText('');
    setUserMessageError('');
    setUserMessageSuccess('');
  };

  const handleRoleChange = async (userId, newRole) => {
    try {
      const response = await fetch(`${API_URL}/admin/users/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify({ role: newRole })
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to update user role');
      }
      
      // Update local state
      setUsers(users.map(user => 
        user.id === userId ? { ...user, role: newRole } : user
      ));
      
      if (selectedUser && selectedUser.id === userId) {
        setSelectedUser({ ...selectedUser, role: newRole });
      }
      
      // If this was a role request, refresh the requests
      fetchRoleRequests();
      setError(''); // Clear any previous errors
    } catch (err) {
      console.error('Error updating user role:', err);
      setError(`Error updating user role: ${err.message}`);
    }
  };

  const handlePasswordReset = async (userId) => {
    setConfirmAction({
      type: 'resetPassword',
      userId,
      message: 'Are you sure you want to reset this user\'s password?',
      confirmText: 'Reset Password',
      action: async () => {
        try {
          const response = await fetch(`${API_URL}/admin/users/${userId}/reset-password`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': localStorage.getItem('auth_token')
            },
            body: JSON.stringify({ newPassword: 'tempPassword123' }) // Default temporary password
          });
          
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || 'Failed to reset password');
          }
          
          setError('');
          alert('Password has been reset to: tempPassword123');
        } catch (err) {
          console.error('Error resetting password:', err);
          setError(`Error resetting password: ${err.message}`);
        } finally {
          setConfirmAction(null);
        }
      }
    });
  };

  const handleDeleteUser = async (userId) => {
    setConfirmAction({
      type: 'deleteUser',
      userId,
      message: 'Are you sure you want to delete this user? This action cannot be undone.',
      confirmText: 'Delete User',
      action: async () => {
        try {
          const response = await fetch(`${API_URL}/admin/users/${userId}`, {
            method: 'DELETE',
            headers: {
              'x-auth-token': localStorage.getItem('auth_token')
            }
          });
          
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || 'Failed to delete user');
          }
          
          // Update local state
          setUsers(users.filter(user => user.id !== userId));
          
          if (selectedUser && selectedUser.id === userId) {
            setSelectedUser(null);
          }
          
          setError('');
        } catch (err) {
          console.error('Error deleting user:', err);
          setError(`Error deleting user: ${err.message}`);
        } finally {
          setConfirmAction(null);
        }
      }
    });
  };

  const handleApproveRequest = async (requestId, userId) => {
    try {
      // First update the user's role
      await handleRoleChange(userId, 'adventurer');
      
      // Then mark the request as approved
      const response = await fetch(`${API_URL}/admin/role-requests/${requestId}/approve`, {
        method: 'PUT',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to approve request');
      }
      
      // Update local state
      setRoleRequests(roleRequests.filter(req => req.id !== requestId));
      setError(''); // Clear any previous errors
    } catch (err) {
      console.error('Error approving request:', err);
      setError(`Error approving request: ${err.message}`);
    }
  };

  const handleDenyRequest = async (requestId) => {
    try {
      const response = await fetch(`${API_URL}/admin/role-requests/${requestId}/deny`, {
        method: 'PUT',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to deny request');
      }
      
      // Update local state
      setRoleRequests(roleRequests.filter(req => req.id !== requestId));
      setError(''); // Clear any previous errors
    } catch (err) {
      console.error('Error denying request:', err);
      setError(`Error denying request: ${err.message}`);
    }
  };

  const handleCharacterSheetUpload = async (e) => {
    const file = e.target.files[0];
    if (!file || !selectedUser) return;
    
    // Check file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setCharacterSheetError('File too large. Maximum size is 5MB.');
      return;
    }
    
    // Check file type
    const isJSON = file.type === 'application/json';
    const isPDF = file.type === 'application/pdf';
    
    if (!isJSON && !isPDF) {
      setCharacterSheetError('Only JSON and PDF files are allowed.');
      return;
    }
    
    setUploadingCharacterSheet(true);
    setCharacterSheetError('');
    setCharacterSheetSuccess('');
    
    try {
      // Create FormData object for file upload
      const formData = new FormData();
      
      // Add user ID to the form data
      formData.append('userId', selectedUser.id);
      
      let endpoint = `${API_URL}/admin/upload/character-sheet`;
      
      // Handle PDF uploads differently
      if (isPDF) {
        formData.append('characterSheetPDF', file);
        endpoint = `${API_URL}/admin/upload/character-sheet-pdf`;
      } else {
        formData.append('characterSheet', file);
      }
      
      // Upload the file to the server
      console.log(`Uploading ${isPDF ? 'PDF' : 'JSON'} character sheet for user:`, selectedUser.id);
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: formData
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `Failed to upload ${isPDF ? 'PDF' : 'JSON'} character sheet`);
      }
      
      const data = await response.json();
      console.log('Character sheet uploaded successfully:', data);
      
      setCharacterSheetSuccess(`${isPDF ? 'PDF' : 'JSON'} character sheet uploaded successfully`);
      
      // Clear the success message after 3 seconds
      setTimeout(() => {
        setCharacterSheetSuccess('');
      }, 3000);
    } catch (err) {
      setCharacterSheetError(err.message || 'An error occurred during upload');
      console.error('Error uploading character sheet:', err);
    } finally {
      setUploadingCharacterSheet(false);
      
      // Reset the file input
      if (e.target) {
        e.target.value = '';
      }
    }
  };

  const handleRetry = () => {
    setError('');
    setActiveTab('users');
    fetchUsers();
    fetchRoleRequests();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };
  
  // Filter users based on search term
  const filteredUsers = users.filter(user => 
    user.displayName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.role?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Apply role filter and sorting
  const filteredAndSortedUsers = useMemo(() => {
    // First, apply the role filter if one is active
    let filteredList = [...filteredUsers];
    
    if (activeFilter) {
      filteredList = filteredList.filter(user => user.role === activeFilter);
    }
    
    // Then, sort the list based on selected criteria
    return filteredList.sort((a, b) => {
      switch (sortCriteria) {
        case 'name':
          return (a.displayName || '').localeCompare(b.displayName || '');
        case 'role':
          return (a.role || '').localeCompare(b.role || '');
        case 'recent':
          return new Date(b.createdAt) - new Date(a.createdAt);
        default:
          return 0;
      }
    });
  }, [filteredUsers, activeFilter, sortCriteria]);

  const handleLogout = () => {
    logout();
  };

  const fetchGalleryCategories = async () => {
    try {
      setIsLoadingGallery(true);
      setGalleryError('');
      
      const response = await fetch(`${API_URL}/gallery/categories`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setGalleryCategories(data.categories);
      } else {
        setGalleryError('Failed to load gallery categories');
      }
    } catch (error) {
      console.error('Error fetching gallery categories:', error);
      setGalleryError('An error occurred while loading the gallery');
    } finally {
      setIsLoadingGallery(false);
    }
  };

  const fetchFolderImages = async (category, folderPath) => {
    try {
      setIsLoadingGallery(true);
      setGalleryError('');
      
      console.log(`Fetching images from: category=${category}, folder=${folderPath}`);
      
      let url;
      if (!folderPath || folderPath === '') {
        // For direct image folders, don't include the folderPath in the URL
        url = `${API_URL}/gallery/folder/${category}`;
      } else {
        url = `${API_URL}/gallery/folder/${category}/${folderPath}`;
      }
      
      console.log('Request URL:', url);
      
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        console.log(`Received ${data.images.length} images:`, data.images);
        setGalleryImages(data.images);
        setCurrentFolder({ category, path: folderPath });
      } else {
        const errorData = await response.text();
        console.error('Failed to load folder images:', errorData);
        setGalleryError(`Failed to load folder images: ${errorData}`);
      }
    } catch (error) {
      console.error('Error fetching folder images:', error);
      setGalleryError('An error occurred while loading the images');
    } finally {
      setIsLoadingGallery(false);
    }
  };

  const handleBackToFolders = () => {
    setCurrentFolder(null);
    setGalleryImages([]);
  };

  const handleFolderClick = (category, folder) => {
    console.log('Folder clicked:', category, folder);
    
    // Handle direct image folders (which have empty path)
    const folderPath = folder.path || '';
    
    console.log(`Requesting images for category=${category}, folderPath=${folderPath}`);
    fetchFolderImages(category, folderPath);
  };

  const handleImageClick = (image) => {
    const index = galleryImages.findIndex(img => img.url === image.url);
    setCurrentImageIndex(index);
    setSelectedImage(image);
    
    // Enable pinch zoom by modifying the viewport meta tag
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    if (viewportMeta) {
      viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=5, user-scalable=yes');
    }
  };

  // Close the image modal
  const handleCloseModal = () => {
    setSelectedImage(null);
    
    // Reset viewport meta tag when closing the modal
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    if (viewportMeta) {
      viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no');
    }
  };

  // Handle next image in gallery
  const handleNextImage = () => {
    if (galleryImages.length <= 1) return;
    const nextIndex = (currentImageIndex + 1) % galleryImages.length;
    setCurrentImageIndex(nextIndex);
    setSelectedImage(galleryImages[nextIndex]);
  };

  // Handle previous image in gallery
  const handlePrevImage = () => {
    if (galleryImages.length <= 1) return;
    const prevIndex = (currentImageIndex - 1 + galleryImages.length) % galleryImages.length;
    setCurrentImageIndex(prevIndex);
    setSelectedImage(galleryImages[prevIndex]);
  };

  const toggleImageZoom = () => {
    setImageZoomed(!imageZoomed);
  };

  const handleMarkAllAsRead = async () => {
    console.log('Mark all as read is not implemented');
  };

  const handleMarkAsRead = async (notificationId) => {
    console.log('Mark as read is not implemented');
  };

  const handleDeleteNotification = async (notificationId) => {
    console.log('Delete notification is not implemented');
  };

  const handleSelectAll = () => {
    if (filteredUsers.length > 0) {
      if (selectedUserIds.length === filteredUsers.length) {
        setSelectedUserIds([]);
      } else {
        setSelectedUserIds(filteredUsers.map(user => user.id));
      }
    }
  };

  const handleUserCheckboxToggle = (userId) => {
    if (selectedUserIds.includes(userId)) {
      setSelectedUserIds(selectedUserIds.filter(id => id !== userId));
    } else {
      setSelectedUserIds([...selectedUserIds, userId]);
    }
  };

  const handleBatchRoleChange = () => {
    setShowBatchRoleEdit(true);
  };

  const saveBatchRoleEdit = async () => {
    try {
      if (selectedUserIds.length > 0 && batchRole) {
        for (const userId of selectedUserIds) {
          await handleRoleChange(userId, batchRole);
        }
        setSelectedUserIds([]);
        setBatchRole("");
        setShowBatchRoleEdit(false);
      }
    } catch (err) {
      console.error('Error batch role change:', err);
      setError(`Error batch role change: ${err.message}`);
    }
  };

  const handleBatchDelete = () => {
    if (selectedUserIds.length === 0) return;
    
    setConfirmAction({
      type: 'batchDeleteUsers',
      message: `Are you sure you want to delete ${selectedUserIds.length} users? This action cannot be undone.`,
      confirmText: 'Delete Users',
      action: async () => {
        try {
          for (const userId of selectedUserIds) {
            const response = await fetch(`${API_URL}/admin/users/${userId}`, {
              method: 'DELETE',
              headers: {
                'x-auth-token': localStorage.getItem('auth_token')
              }
            });
            
            if (!response.ok) {
              const errorData = await response.json().catch(() => ({}));
              throw new Error(errorData.message || 'Failed to delete some users');
            }
          }
          
          // Update local state
          setUsers(users.filter(user => !selectedUserIds.includes(user.id)));
          
          if (selectedUser && selectedUserIds.includes(selectedUser.id)) {
            setSelectedUser(null);
          }
          
          setSelectedUserIds([]);
          setError('');
        } catch (err) {
          console.error('Error deleting users:', err);
          setError(`Error deleting users: ${err.message}`);
        } finally {
          setConfirmAction(null);
        }
      }
    });
  };

  const handleQuickRoleChange = (newRole) => {
    if (quickEditUser) {
      setQuickEditUser({...quickEditUser, role: newRole});
    }
  };

  const saveQuickRoleEdit = async () => {
    if (quickEditUser) {
      await handleRoleChange(quickEditUser.id, quickEditUser.role);
      setQuickEditUser(null);
      setShowQuickRoleEdit(false);
    }
  };

  // Add function to handle user profile updates
  const handleUserProfileUpdate = async () => {
    if (!selectedUser) return;
    
    try {
      console.log('Updating user profile:', selectedUser.id, userProfileData);
      
      const response = await fetch(`${API_URL}/admin/users/${selectedUser.id}/profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify(userProfileData)
      });
      
      const responseData = await response.json();
      console.log('Profile update response:', response.status, responseData);
      
      if (!response.ok) {
        throw new Error(responseData.message || `Server responded with status: ${response.status}`);
      }
      
      // Update local state
      setUsers(users.map(user => 
        user.id === selectedUser.id ? { ...user, ...userProfileData } : user
      ));
      
      setSelectedUser({ ...selectedUser, ...userProfileData });
      
      setProfileUpdateMessage({ 
        type: 'success', 
        message: 'Profile updated successfully' 
      });
      
      setTimeout(() => {
        setProfileUpdateMessage({ type: '', message: '' });
      }, 3000);
      
      setEditUserProfile(false);
    } catch (err) {
      console.error('Error updating user profile:', err);
      setProfileUpdateMessage({ 
        type: 'error', 
        message: `Error updating profile: ${err.message}` 
      });
    }
  };

  // Add function to send message to adventurers
  const handleSendAdventurerMessage = async () => {
    if (!adventurerMessageSubject.trim() || !adventurerMessage.trim()) {
      setAdventurerMessageError('Subject and message are required');
      return;
    }
    
    setSendingAdventurerMessage(true);
    setAdventurerMessageError('');
    setAdventurerMessageSuccess('');
    
    try {
      const response = await fetch(`${API_URL}/admin/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify({
          subject: adventurerMessageSubject,
          content: adventurerMessage,
          targetRole: 'adventurer' // Send to all adventurers
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to send message');
      }
      
      setAdventurerMessageSuccess('Message sent successfully to all adventurers');
      setAdventurerMessageSubject('');
      setAdventurerMessage('');
      
      setTimeout(() => {
        setShowAdventurerMessageForm(false);
        setAdventurerMessageSuccess('');
      }, 2000);
    } catch (err) {
      console.error('Error sending message to adventurers:', err);
      setAdventurerMessageError(`Error: ${err.message}`);
    } finally {
      setSendingAdventurerMessage(false);
    }
  };

  // Add function to fetch user messages
  const fetchUserMessages = async (userId) => {
    if (!userId) return;
    
    setLoadingMessages(true);
    setMessageError('');
    
    try {
      const response = await fetch(`${API_URL}/admin/users/${userId}/messages`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to fetch messages');
      }
      
      const data = await response.json();
      console.log(`Retrieved ${data.length} messages for user ${userId}`);
      setUserMessages(data);
      
      // If this is the same user selected in the messages tab, update that view too
      if (selectedMessageUser && selectedMessageUser.id === userId) {
        setAllMessages(data);
      }
    } catch (err) {
      console.error('Error fetching user messages:', err);
      setMessageError(`Error: ${err.message}`);
    } finally {
      setLoadingMessages(false);
    }
  };

  // Add function to delete a message
  const handleDeleteMessage = async (message) => {
    // Accept either a message object or direct messageId
    const messageId = typeof message === 'object' ? (message.id || message.docId) : message;
    
    // Get userId from the message object or the selected user
    let userId;
    if (typeof message === 'object' && message.userId) {
      userId = message.userId;
    } else if (selectedUser) {
      userId = selectedUser.id;
    } else if (selectedMessageUser) {
      userId = selectedMessageUser.id;
    } else {
      console.error("Cannot delete: Missing user ID");
      setError("Cannot delete message: missing user ID");
      return;
    }
    
    if (!messageId) {
      console.error("Cannot delete: Invalid message ID", message);
      setError("Cannot delete message: missing ID");
      return;
    }
    
    console.log(`Preparing to delete message with ID: ${messageId} for user: ${userId}`);
    
    setConfirmAction({
      type: 'deleteMessage',
      messageId,
      message: 'Are you sure you want to delete this message? This action cannot be undone.',
      confirmText: 'Delete Message',
      action: async () => {
        try {
          console.log(`Deleting message with ID: ${messageId} for user: ${userId}`);
          
          // Show loading state
          setError('');
          setLoadingMessages(true);
          
          // Add userId to query string
          const response = await fetch(`${API_URL}/admin/messages/${messageId}?userId=${userId}`, {
            method: 'DELETE',
            headers: {
              'x-auth-token': localStorage.getItem('auth_token')
            }
          });
          
          console.log(`Delete response status: ${response.status}`);
          
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            console.error('Server error response:', errorData);
            throw new Error(errorData.message || 'Failed to delete message');
          }
          
          // Update all relevant message lists
          if (selectedUser) {
            console.log(`Refreshing messages for user ${selectedUser.id}`);
            await fetchUserMessages(selectedUser.id);
          }
          
          if (selectedMessageUser && (!selectedUser || selectedUser.id !== selectedMessageUser.id)) {
            console.log(`Refreshing messages for message user ${selectedMessageUser.id}`);
            await fetchUserMessagesForTab(selectedMessageUser.id);
          }
          
          setError('');
          // Show success message
          setUserMessageSuccess('Message deleted successfully');
          setTimeout(() => setUserMessageSuccess(''), 3000);
        } catch (err) {
          console.error('Error deleting message:', err);
          setError(`Error deleting message: ${err.message}`);
        } finally {
          setConfirmAction(null);
          setLoadingMessages(false);
        }
      }
    });
  };

  // Add function to set up message editing
  const handleEditMessage = (message) => {
    console.log("Setting up message edit with message:", message);
    
    // Make sure we're using the correct ID
    const messageId = message.id || message.docId;
    if (!messageId) {
      console.error("Message doesn't have a valid ID:", message);
      setError("Cannot edit message: missing ID");
      return;
    }
    
    const editableMessage = {
      ...message,
      id: messageId
    };
    
    setEditMessage(editableMessage);
    setUserMessageText(message.content || '');
    setUserMessageSubject(message.subject || '');
    setShowUserMessageForm(true);
  };

  // Update handleSendUserMessage to include subject field and handle message editing
  const handleSendUserMessage = async () => {
    // Use either selectedUser or selectedMessageUser (for Messages tab)
    const targetUser = selectedUser || selectedMessageUser;
    
    if (!targetUser) {
      setUserMessageError('User selection is required');
      return;
    }
    
    // Debug logs to see what's in the message content
    console.log('Message text:', userMessageText);
    console.log('Message text trimmed:', userMessageText.trim());
    console.log('Message text length:', userMessageText.length);
    console.log('Message text trimmed length:', userMessageText.trim().length);
    
    // Skipping empty message check - let server handle validation
    /*
    if ((!userMessageText || userMessageText.length === 0) && !editMessage) {
      setUserMessageError('Message content is required');
      return;
    }
    */
    
    setSendingUserMessage(true);
    setUserMessageError('');
    setUserMessageSuccess('');
    
    try {
      let url = `${API_URL}/admin/users/${targetUser.id}/message`;
      let method = 'POST';
      let body = {
        content: userMessageText,
        subject: userMessageSubject
      };
      
      // If editing an existing message
      if (editMessage) {
        url = `${API_URL}/admin/messages/${editMessage.id}`;
        method = 'PUT';
        body = {
          content: userMessageText,
          subject: userMessageSubject,
          userId: targetUser.id
        };
        
        console.log("Message edit - sending update with data:", {
          messageId: editMessage.id,
          userId: targetUser.id,
          content: userMessageText,
          subject: userMessageSubject,
          url,
          method
        });
      }
      
      // Log what we're sending
      console.log(`Sending request to ${url} with method ${method}`);
      console.log('Request body:', JSON.stringify(body));
      
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify(body)
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to send message');
      }
      
      setUserMessageSuccess(editMessage ? 'Message updated successfully' : 'Message sent successfully');
      setUserMessageText('');
      setUserMessageSubject('');
      setEditMessage(null);
      
      // Refresh the messages - use the appropriate fetch based on context
      if (activeTab === 'messages' && targetUser.id === selectedMessageUser?.id) {
        await fetchUserMessagesForTab(targetUser.id);
      } else if (targetUser.id === selectedUser?.id) {
        await fetchUserMessages(targetUser.id);
      }
      
      setTimeout(() => {
        setUserMessageSuccess('');
        setShowUserMessageForm(false);
      }, 2000);
    } catch (err) {
      console.error('Error sending/updating message:', err);
      setUserMessageError(`Error: ${err.message}`);
    } finally {
      setSendingUserMessage(false);
    }
  };

  // Add function to fetch all users with messages
  const fetchMessageUsers = async () => {
    setLoadingAllMessages(true);
    setError('');
    
    try {
      const response = await fetch(`${API_URL}/admin/users`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `Server responded with status: ${response.status}`);
      }
      
      const allUsers = await response.json();
      
      // Set these users for the messages tab
      setMessageUsers(allUsers);
      
      if (selectedMessageUser) {
        // If a user was previously selected, refresh their messages
        await fetchUserMessagesForTab(selectedMessageUser.id);
      } else if (allUsers.length > 0) {
        // If no user selected, select the first user
        setSelectedMessageUser(allUsers[0]);
        await fetchUserMessagesForTab(allUsers[0].id);
      }
    } catch (err) {
      console.error('Error fetching message users:', err);
      setError(`Error fetching users: ${err.message}`);
    } finally {
      setLoadingAllMessages(false);
    }
  };

  // Function to fetch messages for a specific user in the messages tab
  const fetchUserMessagesForTab = async (userId) => {
    if (!userId) return;
    
    setLoadingAllMessages(true);
    setError('');
    
    try {
      console.log(`Fetching messages for user: ${userId}`);
      const response = await fetch(`${API_URL}/admin/users/${userId}/messages`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to fetch messages');
      }
      
      const data = await response.json();
      console.log(`Retrieved ${data.length} messages for user ${userId} in messages tab`);
      setAllMessages(data);
      
      // Also update the user-specific messages if this is the selected user
      if (selectedUser && selectedUser.id === userId) {
        setUserMessages(data);
      }
    } catch (err) {
      console.error('Error fetching user messages for tab:', err);
      setError(`Error: ${err.message}`);
    } finally {
      setLoadingAllMessages(false);
    }
  };

  // Function to handle selecting a user in the messages tab
  const handleMessageUserSelect = async (user) => {
    setSelectedMessageUser(user);
    await fetchUserMessagesForTab(user.id);
  };

  // Function to toggle pin status of a message
  const handleTogglePin = async (message) => {
    if (togglingPin) return; // Prevent multiple simultaneous toggles
    
    setTogglingPin(true);
    
    try {
      const response = await fetch(`${API_URL}/admin/messages/${message.id}/pin`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify({
          pinned: !message.pinned,
          userId: selectedMessageUser.id
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to update pin status');
      }
      
      // Update the message in state
      setAllMessages(allMessages.map(msg => 
        msg.id === message.id ? { ...msg, pinned: !message.pinned } : msg
      ));
      
    } catch (err) {
      console.error('Error toggling pin status:', err);
      setError(`Error: ${err.message}`);
    } finally {
      setTogglingPin(false);
    }
  };

  // Render HTML content safely using an iframe to isolate the content
  const createIframeSrc = (content) => {
    const htmlContent = `
<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <style>
    body {
      margin: 0;
      padding: 0;
      font-family: 'Crimson Text', serif;
      color: #e2e8f0;
      background-color: transparent;
      font-size: 14px;
      line-height: 1.5;
      overflow-wrap: break-word;
      width: 100%;
    }
    
    /* Ensure all content is fully visible */
    html, body {
      min-height: 100%;
      height: auto;
      overflow: visible;
      width: 100%;
    }

    /* Style reset to ensure no containers appear */
    * {
      border: none !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      background: transparent !important;
      padding: 0 !important;
      margin: 0 !important;
    }
    
    /* Hide any terminal elements */
    [data-terminal], [class*="terminal"], [class*="Terminal"], [id*="terminal"], [id*="Terminal"], 
    [class*="dominion"], [class*="Dominion"], [id*="dominion"], [id*="Dominion"],
    [class*="classified"], [class*="Classified"] {
      display: none !important;
    }

    /* Headers for terminal should be hidden */
    div:first-child:contains("DOMINION TERMINAL"), 
    div:contains("CLASSIFIED"), 
    div:contains("SESSION ID") {
      display: none !important;
    }
    
    /* Only paragraphs of content should be visible */
    p {
      margin-bottom: 10px !important;
      display: block !important;
    }
    
    /* Always ensure links are visible */
    a { color: #ffd700; text-decoration: underline; }
  </style>
  <script>
    function updateHeight() {
      const height = document.body.scrollHeight;
      window.parent.postMessage({ type: 'resize', height: height }, '*');
    }
    
    window.onload = function() {
      // Remove any DOMINION TERMINAL elements that might be in the content
      const terminalElements = document.querySelectorAll('[class*="terminal"],[class*="Terminal"],[id*="terminal"],[id*="Terminal"],[class*="dominion"],[class*="Dominion"],[class*="classified"]');
      terminalElements.forEach(el => {
        el.style.display = 'none';
      });
      
      // Keep only the actual message content
      const terminalHeaders = Array.from(document.querySelectorAll('div')).filter(div => 
        div.textContent.includes('DOMINION TERMINAL') || 
        div.textContent.includes('CLASSIFIED') ||
        div.textContent.includes('SESSION ID')
      );
      terminalHeaders.forEach(el => {
        el.style.display = 'none';
      });
      
      updateHeight();
      setTimeout(updateHeight, 100);
      setTimeout(updateHeight, 500);
    };
    
    const observer = new MutationObserver(updateHeight);
    observer.observe(document.body, { 
      childList: true, 
      subtree: true,
      attributes: true,
      characterData: true
    });
  </script>
</head>
<body>${content || ''}</body>
</html>`;
    
    return `data:text/html;charset=utf-8,${encodeURIComponent(htmlContent)}`;
  };

  return (
    <div className="admin-dashboard">
      <div className="dashboard-tabs">
        <button 
          className={`tab-button ${activeTab === 'users' ? 'active' : ''}`}
          onClick={() => setActiveTab('users')}
        >
          User Management
        </button>
        <button 
          className={`tab-button ${activeTab === 'requests' ? 'active' : ''}`}
          onClick={() => setActiveTab('requests')}
        >
          Role Requests {roleRequests.length > 0 && <span className="request-badge">{roleRequests.length}</span>}
        </button>
        <button 
          className={`tab-button ${activeTab === 'links' ? 'active' : ''}`}
          onClick={() => setActiveTab('links')}
        >
          Links
        </button>
        <button 
          className={`tab-button ${activeTab === 'messages' ? 'active' : ''}`}
          onClick={() => setActiveTab('messages')}
        >
          Messages
        </button>
        <button 
          className="tab-button logout-tab"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
      
      {error && (
        <div className="admin-error">
          <p>{error}</p>
          <button className="retry-button" onClick={handleRetry}>Retry</button>
        </div>
      )}
      
      <div className="tab-content">
        {activeTab === 'overview' && (
          <div className="overview-tab">
            <div className="dashboard-stats">
              <div className="stat-card">
                <h3>Total Users</h3>
                <div className="stat-value">{users.length}</div>
              </div>
              <div className="stat-card">
                <h3>Adventurers</h3>
                <div className="stat-value">
                  {users.filter(user => user.role === 'adventurer').length}
                </div>
              </div>
              <div className="stat-card">
                <h3>Pending Requests</h3>
                <div className="stat-value">{roleRequests.length}</div>
              </div>
              <div className="stat-card">
                <h3>Gallery Categories</h3>
                <div className="stat-value">{galleryCategories.length}</div>
              </div>
            </div>

            <div className="dashboard-sections">
              <div className="activity-section">
                <h3>Recent Activity</h3>
                <div className="activity-list">
                  {roleRequests.length > 0 ? (
                    <div className="activity-item">
                      <div className="activity-icon request-icon">🔔</div>
                      <div className="activity-content">
                        <div className="activity-title">{roleRequests.length} pending adventurer requests</div>
                        <div className="activity-action">
                          <button 
                            className="action-button small"
                            onClick={() => setActiveTab('requests')}
                          >
                            Review
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="no-activity">No recent activity</div>
                  )}
                </div>
              </div>

              <div className="quick-actions">
                <h3>Quick Actions</h3>
                <div className="quick-action-buttons">
                  <button 
                    className="dashboard-action-button"
                    onClick={() => setActiveTab('users')}
                  >
                    <div className="action-icon">👥</div>
                    <div className="action-label">Manage Users</div>
                  </button>
                  
                  <button 
                    className="dashboard-action-button"
                    onClick={() => setActiveTab('requests')}
                  >
                    <div className="action-icon">🔔</div>
                    <div className="action-label">Review Requests</div>
                  </button>
                  
                  <button 
                    className="dashboard-action-button"
                    onClick={() => setActiveTab('gallery')}
                  >
                    <div className="action-icon">🖼️</div>
                    <div className="action-label">Gallery</div>
                  </button>
                  
                  <button 
                    className="dashboard-action-button"
                    onClick={() => setShowAdventurerMessageForm(true)}
                  >
                    <div className="action-icon">📝</div>
                    <div className="action-label">Send Message</div>
                  </button>
                </div>
              </div>
            </div>
            
            {showAdventurerMessageForm && (
              <div className="message-form adventurer-message-form">
                <h4>Send Message to All Adventurers</h4>
                
                {adventurerMessageSuccess && (
                  <div className="message-success">
                    {adventurerMessageSuccess}
                  </div>
                )}
                
                {adventurerMessageError && (
                  <div className="message-error">
                    {adventurerMessageError}
                  </div>
                )}
                
                <div className="form-group">
                  <label>Subject</label>
                  <input 
                    type="text"
                    value={adventurerMessageSubject}
                    onChange={(e) => setAdventurerMessageSubject(e.target.value)}
                    className="profile-input"
                    placeholder="Message subject"
                  />
                </div>
                
                <div className="form-group">
                  <label>Message (HTML supported)</label>
                  <textarea
                    value={adventurerMessage}
                    onChange={(e) => setAdventurerMessage(e.target.value)}
                    className="profile-input"
                    rows={6}
                    placeholder="Enter message content..."
                  />
                </div>
                
                <div className="message-actions">
                  <button
                    className="send-message-btn"
                    onClick={handleSendAdventurerMessage}
                    disabled={sendingAdventurerMessage}
                  >
                    {sendingAdventurerMessage ? (
                      <>Sending... <span className="loading-spinner-small"></span></>
                    ) : (
                      "Send Message"
                    )}
                  </button>
                  <button
                    className="cancel-message-btn"
                    onClick={() => {
                      setShowAdventurerMessageForm(false);
                      setAdventurerMessageSubject('');
                      setAdventurerMessage('');
                      setAdventurerMessageError('');
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        )}

        {activeTab === 'users' && (
          <div className="users-tab">
            <div className="users-control-panel">
              <div className="filter-controls">
                <div className="search-bar">
                  <input
                    type="text"
                    placeholder="Search users..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="role-filters">
                  <button 
                    className={`filter-button ${!activeFilter ? 'active' : ''}`}
                    onClick={() => setActiveFilter(null)}
                  >
                    All Users
                  </button>
                  <button 
                    className={`filter-button ${activeFilter === 'admin' ? 'active' : ''}`}
                    onClick={() => setActiveFilter('admin')}
                  >
                    Admins
                  </button>
                  <button 
                    className={`filter-button ${activeFilter === 'adventurer' ? 'active' : ''}`}
                    onClick={() => setActiveFilter('adventurer')}
                  >
                    Adventurers
                  </button>
                  <button 
                    className={`filter-button ${activeFilter === 'commoner' ? 'active' : ''}`}
                    onClick={() => setActiveFilter('commoner')}
                  >
                    Commoners
                  </button>
                </div>
              </div>
              <div className="user-actions-toolbar">
                <div className="sort-controls">
                  <label>Sort by:</label>
                  <select 
                    value={sortCriteria} 
                    onChange={(e) => setSortCriteria(e.target.value)}
                    className="sort-dropdown"
                  >
                    <option value="name">Name</option>
                    <option value="role">Role</option>
                    <option value="recent">Recently Joined</option>
                  </select>
                </div>
                <div className="batch-selection-controls">
                  <button 
                    className="action-button"
                    onClick={handleSelectAll}
                    disabled={filteredAndSortedUsers.length === 0}
                  >
                    {selectedUserIds.length === filteredAndSortedUsers.length && selectedUserIds.length > 0 ? 'Deselect All' : 'Select All'}
                  </button>
                  {selectedUserIds.length > 0 && (
                    <div className="batch-actions">
                      <button 
                        className="action-button"
                        onClick={handleBatchRoleChange}
                      >
                        Set Role
                      </button>
                      <button 
                        className="action-button delete-user"
                        onClick={handleBatchDelete}
                      >
                        Delete Selected
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            
            {loading ? (
              <div className="loading-users">
                <div className="loading-spinner"></div>
                <p>Loading users...</p>
              </div>
            ) : (
              <div className="users-list">
                {filteredAndSortedUsers.length > 0 ? (
                  filteredAndSortedUsers.map(user => (
                    <div key={user.id}>
                      <div 
                        className={`user-item ${selectedUser && selectedUser.id === user.id ? 'selected' : ''} ${selectedUserIds.includes(user.id) ? 'batch-selected' : ''}`}
                      >
                        <div className="user-select">
                          <input 
                            type="checkbox" 
                            checked={selectedUserIds.includes(user.id)}
                            onChange={() => handleUserCheckboxToggle(user.id)}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </div>
                        <div 
                          className="user-content"
                          onClick={() => handleUserSelect(user)}
                        >
                          <div className="user-avatar">
                            {user.avatar ? (
                              <img src={user.avatar} alt={user.displayName} />
                            ) : (
                              <div className="avatar-placeholder">
                                {user.displayName?.charAt(0).toUpperCase() || 'U'}
                              </div>
                            )}
                          </div>
                          <div className="user-info">
                            <div className="user-name">{user.displayName}</div>
                            <div className="user-email">{user.email}</div>
                          </div>
                          <div className={`user-role role-${user.role}`}>
                            {user.role}
                          </div>
                          <div className="user-joined">
                            <div className="detail-label">Joined</div>
                            <div>{formatDate(user.createdAt).split(',')[0]}</div>
                          </div>
                          <div className="user-quick-actions">
                            <button 
                              className="icon-button" 
                              title="Change Role"
                              onClick={(e) => {
                                e.stopPropagation();
                                setQuickEditUser(user);
                                setShowQuickRoleEdit(true);
                              }}
                            >
                              👑
                            </button>
                            <button 
                              className="icon-button message-user"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedUser(user);
                                setShowUserMessageForm(true);
                              }}
                            >
                              Send Message
                            </button>
                            <button 
                              className="icon-button delete" 
                              title="Delete User"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteUser(user.id);
                              }}
                            >
                              🗑️
                            </button>
                          </div>
                        </div>
                      </div>
                      
                      {/* User details panel rendered directly under the selected user */}
                      {selectedUser && selectedUser.id === user.id && showUserDetails && (
                        <div className="user-details-inline">
                          {!editUserProfile ? (
                            <>
                              <div className="detail-item">
                                <div className="detail-label">Email</div>
                                <div className="detail-value">{selectedUser.email}</div>
                              </div>
                              <div className="detail-item">
                                <div className="detail-label">Display Name</div>
                                <div className="detail-value">{selectedUser.displayName}</div>
                              </div>
                              <div className="detail-item">
                                <div className="detail-label">User ID</div>
                                <div className="detail-value">{selectedUser.id}</div>
                              </div>
                              <div className="detail-item">
                                <div className="detail-label">Role</div>
                                <div className="role-selector">
                                  <select 
                                    value={selectedUser.role} 
                                    onChange={(e) => handleRoleChange(selectedUser.id, e.target.value)}
                                  >
                                    <option value="commoner">Commoner</option>
                                    <option value="adventurer">Adventurer</option>
                                    <option value="admin">Admin</option>
                                  </select>
                                </div>
                              </div>
                              <div className="detail-item">
                                <div className="detail-label">Character Sheet</div>
                                <div className="detail-value">
                                  <label className="action-button">
                                    Upload Character Sheet
                                    <input 
                                      type="file" 
                                      accept="application/json,application/pdf,.json,.pdf" 
                                      onChange={handleCharacterSheetUpload} 
                                      disabled={uploadingCharacterSheet || selectedUser.role !== 'adventurer'}
                                      style={{ display: 'none' }}
                                    />
                                  </label>
                                  {uploadingCharacterSheet && <span className="loading-spinner-small"></span>}
                                  {selectedUser.role !== 'adventurer' && (
                                    <div className="character-sheet-note">
                                      User must be an Adventurer to have a character sheet
                                    </div>
                                  )}
                                  {characterSheetError && (
                                    <div className="message-error">{characterSheetError}</div>
                                  )}
                                  {characterSheetSuccess && (
                                    <div className="message-success">{characterSheetSuccess}</div>
                                  )}
                                </div>
                              </div>
                              <div className="detail-item">
                                <div className="detail-label">Member Since</div>
                                <span className="detail-value">{formatDate(selectedUser.createdAt)}</span>
                              </div>
                              
                              <div className="user-actions">
                                <button 
                                  className="action-button edit-profile"
                                  onClick={() => setEditUserProfile(true)}
                                >
                                  Edit Profile
                                </button>
                                <button 
                                  className="action-button message-user"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedUser(user);
                                    setShowUserMessageForm(true);
                                  }}
                                >
                                  Send Message
                                </button>
                                <button 
                                  className="action-button reset-password"
                                  onClick={() => handlePasswordReset(selectedUser.id)}
                                >
                                  Reset Password
                                </button>
                                <button 
                                  className="action-button delete-user"
                                  onClick={() => handleDeleteUser(selectedUser.id)}
                                >
                                  Delete User
                                </button>
                              </div>
                              
                              {/* Message form for specific user */}
                              {showUserMessageForm && (
                                <div className="message-section">
                                  <div className="message-form">
                                    <h4>{editMessage ? 'Edit Message' : `Send Message to ${selectedUser.displayName}`}</h4>
                                    
                                    {userMessageSuccess && (
                                      <div className="message-success">
                                        {userMessageSuccess}
                                      </div>
                                    )}
                                    
                                    {userMessageError && (
                                      <div className="message-error">
                                        {userMessageError}
                                      </div>
                                    )}
                                    
                                    <div className="form-group">
                                      <label>Subject</label>
                                      <input 
                                        type="text"
                                        value={userMessageSubject}
                                        onChange={(e) => setUserMessageSubject(e.target.value)}
                                        className="profile-input"
                                        placeholder="Message subject"
                                      />
                                    </div>
                                    
                                    <div className="form-group">
                                      <label>Message Content (HTML supported)</label>
                                      <textarea
                                        value={userMessageText}
                                        onChange={(e) => setUserMessageText(e.target.value)}
                                        className="profile-input"
                                        rows={12}
                                        placeholder="Enter message content (HTML supported)..."
                                        style={{ minHeight: "300px", fontFamily: "monospace" }}
                                      />
                                    </div>
                                    
                                    <div className="message-actions">
                                      <button
                                        className="send-message-btn"
                                        onClick={handleSendUserMessage}
                                        disabled={sendingUserMessage}
                                      >
                                        {sendingUserMessage ? (
                                          <>
                                            {editMessage ? 'Updating...' : 'Sending...'} 
                                            <span className="loading-spinner-small"></span>
                                          </>
                                        ) : (
                                          editMessage ? "Update Message" : "Send Message"
                                        )}
                                      </button>
                                      <button
                                        className="cancel-message-btn"
                                        onClick={() => {
                                          setShowUserMessageForm(false);
                                          setUserMessageText('');
                                          setUserMessageSubject('');
                                          setUserMessageError('');
                                          setEditMessage(null);
                                        }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                              
                              {/* User messages list */}
                              {!showUserMessageForm && (
                                <div className="user-messages-section">
                                  <h4>Messages Sent to {selectedUser.displayName}</h4>
                                  
                                  {loadingMessages && (
                                    <div className="loading-messages">
                                      <span className="loading-spinner-small"></span> Loading messages...
                                    </div>
                                  )}
                                  
                                  {messageError && (
                                    <div className="message-error">
                                      {messageError}
                                    </div>
                                  )}
                                  
                                  {!loadingMessages && userMessages.length === 0 && (
                                    <div className="no-messages">
                                      No messages have been sent to this user.
                                    </div>
                                  )}
                                  
                                  {userMessages.length > 0 && (
                                    <div className="user-messages-list">
                                      {userMessages.map((message) => (
                                        <div key={message.id} className="user-message-item">
                                          <div className="message-header">
                                            <div className="message-info">
                                              <span className="message-subject">{message.subject || 'No Subject'}</span>
                                              <span className="message-date">{formatDate(message.createdAt)}</span>
                                            </div>
                                            <div className="message-actions">
                                              <button 
                                                className="icon-button" 
                                                title="Edit Message"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleEditMessage(message);
                                                  setSelectedUser(selectedMessageUser);
                                                  setShowUserMessageForm(true);
                                                }}
                                              >
                                                ✏️
                                              </button>
                                              <button 
                                                className="icon-button delete" 
                                                title="Delete Message"
                                                onClick={() => handleDeleteMessage(message)}
                                              >
                                                🗑️
                                              </button>
                                            </div>
                                          </div>
                                          <div 
                                            className="message-content message-content-isolated"
                                          >
                                            <iframe 
                                              srcDoc={message.content} 
                                              src={createIframeSrc(message.content)}
                                              frameBorder="0" 
                                              scrolling="auto"
                                              title="Message content"
                                              className="content-iframe"
                                              style={{ 
                                                width: '100%', 
                                                minHeight: '50px',
                                                height: 'auto',
                                                border: 'none',
                                                padding: 0,
                                                margin: 0
                                              }}
                                              onLoad={(e) => {
                                                // Add message listener for iframe height updates
                                                const handleMessage = function(event) {
                                                  if (event.data && event.data.type === 'resize') {
                                                    // Add extra padding to prevent text cut-off
                                                    e.target.style.height = (event.data.height + 0) + 'px';
                                                  }
                                                };
                                                
                                                window.addEventListener('message', handleMessage);
                                                
                                                // Clean up event listener when component unmounts
                                                return () => {
                                                  window.removeEventListener('message', handleMessage);
                                                };
                                              }}
                                            ></iframe>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          ) : (
                            <div className="edit-profile-form">
                              <h4>Edit User Profile</h4>
                              {profileUpdateMessage.message && (
                                <div className={`message-${profileUpdateMessage.type}`}>
                                  {profileUpdateMessage.message}
                                </div>
                              )}
                              
                              <div className="form-group">
                                <label>Display Name</label>
                                <input 
                                  type="text" 
                                  value={userProfileData.displayName}
                                  onChange={(e) => setUserProfileData({
                                    ...userProfileData,
                                    displayName: e.target.value
                                  })}
                                  className="profile-input"
                                />
                              </div>
                              
                              <div className="form-group">
                                <label>Email Address</label>
                                <input 
                                  type="email" 
                                  value={userProfileData.email}
                                  onChange={(e) => setUserProfileData({
                                    ...userProfileData,
                                    email: e.target.value
                                  })}
                                  className="profile-input"
                                />
                              </div>
                              
                              <div className="profile-actions">
                                <button 
                                  type="button" 
                                  className="action-button save-profile"
                                  onClick={handleUserProfileUpdate}
                                >
                                  Save Changes
                                </button>
                                <button 
                                  type="button" 
                                  className="action-button cancel-edit"
                                  onClick={() => {
                                    setEditUserProfile(false);
                                    setUserProfileData({
                                      displayName: selectedUser.displayName || '',
                                      email: selectedUser.email || ''
                                    });
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="no-users">No users found matching your criteria</div>
                )}
              </div>
            )}
            
            {/* Quick role edit modal */}
            {showQuickRoleEdit && quickEditUser && (
              <div className="quick-edit-modal">
                <div className="quick-edit-content">
                  <h3>Change Role: {quickEditUser.displayName}</h3>
                  <div className="role-selector">
                    <select 
                      value={quickEditUser.role} 
                      onChange={(e) => handleQuickRoleChange(e.target.value)}
                    >
                      <option value="commoner">Commoner</option>
                      <option value="adventurer">Adventurer</option>
                      <option value="admin">Admin</option>
                    </select>
                  </div>
                  <div className="quick-edit-actions">
                    <button 
                      className="action-button"
                      onClick={saveQuickRoleEdit}
                    >
                      Save
                    </button>
                    <button 
                      className="cancel-button"
                      onClick={() => {
                        setShowQuickRoleEdit(false);
                        setQuickEditUser(null);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
            
            {/* Batch role change modal */}
            {showBatchRoleEdit && (
              <div className="quick-edit-modal">
                <div className="quick-edit-content">
                  <h3>Change Role for {selectedUserIds.length} Users</h3>
                  <div className="role-selector">
                    <select 
                      value={batchRole} 
                      onChange={(e) => setBatchRole(e.target.value)}
                    >
                      <option value="">Select role...</option>
                      <option value="commoner">Commoner</option>
                      <option value="adventurer">Adventurer</option>
                      <option value="admin">Admin</option>
                    </select>
                  </div>
                  <div className="quick-edit-actions">
                    <button 
                      className="action-button"
                      onClick={saveBatchRoleEdit}
                      disabled={!batchRole}
                    >
                      Save
                    </button>
                    <button 
                      className="cancel-button"
                      onClick={() => {
                        setShowBatchRoleEdit(false);
                        setBatchRole("");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'requests' && (
          <div className="requests-tab">
            
            {loading ? (
              <div className="loading-requests">
                <div className="loading-spinner"></div>
                <p>Loading requests...</p>
              </div>
            ) : roleRequests.length > 0 ? (
              <div className="requests-list">
                {roleRequests.map(request => (
                  <div key={request.id} className="request-item">
                    <div className="request-header">
                      <div className="requester-info">
                        <span className="requester-name">{request.userName}</span>
                        <span className="requester-email">{request.userEmail}</span>
                      </div>
                      <div className="request-date">{formatDate(request.createdAt)}</div>
                    </div>
                    
                    <div className="request-reason">
                      <h4>Reason for Request:</h4>
                      <p>{request.reason}</p>
                    </div>
                    
                    <div className="request-actions">
                      <button 
                        className="action-button approve"
                        onClick={() => handleApproveRequest(request.id, request.userId)}
                      >
                        Approve
                      </button>
                      <button 
                        className="action-button deny"
                        onClick={() => handleDenyRequest(request.id)}
                      >
                        Deny
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-requests">
                <p>No pending role requests</p>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'gallery' && (
          <div className="gallery-tab">
            <h3>Image Gallery</h3>
            
            {isLoadingGallery ? (
              <div className="loading-gallery">
                <div className="loading-spinner"></div>
                <p>Loading gallery...</p>
              </div>
            ) : galleryError ? (
              <div className="gallery-error">
                <p>{galleryError}</p>
                <button onClick={currentFolder ? () => fetchFolderImages(currentFolder.category, currentFolder.path) : fetchGalleryCategories} className="retry-button">
                  Retry
                </button>
              </div>
            ) : currentFolder ? (
              <>
                <div className="gallery-header">
                  <button className="back-button" onClick={handleBackToFolders}>
                    &larr; Back to Categories
                  </button>
                  <h4>{currentFolder.category.charAt(0).toUpperCase() + currentFolder.category.slice(1)} / {currentFolder.path}</h4>
                </div>
                
                {galleryImages.length === 0 ? (
                  <div className="no-images">
                    <p>No images found in this folder.</p>
                  </div>
                ) : (
                  <div className="gallery-grid">
                    {galleryImages.map((image, index) => {
                      console.log(`Rendering image ${index}:`, image.url);
                      return (
                        <div 
                          key={`image-${index}`} 
                          className="gallery-item"
                          onClick={() => handleImageClick(image)}
                        >
                          <img 
                            src={image.url} 
                            alt={image.name} 
                            onLoad={() => console.log(`Image loaded successfully: ${image.url}`)}
                            onError={(e) => {
                              console.error(`Failed to load image: ${image.url}`);
                              e.target.src = '/placeholder-image.svg';
                              e.target.alt = 'Image not found';
                            }} 
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            ) : (
              galleryCategories.length === 0 ? (
                <div className="no-folders">
                  <p>No categories available in the gallery.</p>
                </div>
              ) : (
                <>
                  <div className="gallery-categories">
                    {galleryCategories.map(category => {
                      if (category.folders.length === 0) return null;
                      
                      return (
                        <div key={category.path} className="gallery-category">
                          <h4>{category.name}</h4>
                          <div className="folders-grid">
                            {category.folders.map((folder, index) => (
                              <div 
                                key={`folder-${index}`} 
                                className="folder-item"
                                onClick={() => handleFolderClick(category.path, folder)}
                              >
                                <div className="folder-icon">
                                  {folder.isDirectImageFolder ? (
                                    <i className="folder-symbol">🖼️</i>
                                  ) : (
                                    <i className="folder-symbol">📁</i>
                                  )}
                                </div>
                                <div className="folder-name">
                                  {folder.name}
                                  {folder.imageCount > 0 && (
                                    <span className="image-count"> ({folder.imageCount})</span>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )
            )}
            
            {selectedImage && (
              <div className="image-modal-overlay" onClick={handleCloseModal}>
                <div className="image-modal-content" onClick={e => e.stopPropagation()}>
                  <img 
                    src={selectedImage.url} 
                    alt={selectedImage.name}
                    className={`modal-image ${imageZoomed ? 'zoomed' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleImageZoom();
                    }}
                    onError={(e) => {
                      console.error(`Failed to load image in modal: ${selectedImage.url}`);
                      e.target.src = '/placeholder-image.svg';
                      e.target.alt = 'Image not found';
                    }} 
                  />
                  
                  <button 
                    className="modal-close-button"
                    onClick={handleCloseModal}
                    aria-label="Close"
                  >
                    ✕
                  </button>
                  
                  <a 
                    className="modal-download-button" 
                    href={selectedImage.url} 
                    download={selectedImage.name}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e => e.stopPropagation()}
                    aria-label="Download"
                  >
                    ↓
                  </a>
                  
                  {galleryImages.length > 1 && (
                    <>
                      <button 
                        className="modal-prev-button" 
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePrevImage();
                        }}
                        aria-label="Previous image"
                      >
                        &#10094;
                      </button>
                      <button 
                        className="modal-next-button" 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleNextImage();
                        }}
                        aria-label="Next image"
                      >
                        &#10095;
                      </button>
                    </>
                  )}
                  
                  <div className="modal-image-counter">
                    {galleryImages.length > 0 ? `${currentImageIndex + 1} / ${galleryImages.length}` : ''}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'messages' && (
          <div className="messages-tab">
            <h3>Message Management</h3>
            
            {error && <div className="message-error">{error}</div>}
            
            <div className="messages-container">
              {loadingAllMessages ? (
                <div className="loading-messages">
                  <span className="loading-spinner"></span>
                  <p>Loading messages...</p>
                </div>
              ) : (
                <>
                  <div className="messages-sidebar">
                    <div className="messages-filter">
                      <button 
                        className={`filter-button ${messageFilter === 'all' ? 'active' : ''}`}
                        onClick={() => setMessageFilter('all')}
                      >
                        All Messages
                      </button>
                      <button 
                        className={`filter-button ${messageFilter === 'pinned' ? 'active' : ''}`}
                        onClick={() => setMessageFilter('pinned')}
                      >
                        Pinned Messages
                      </button>
                    </div>
                    
                    <div className="user-messages-list">
                      <h4>Users</h4>
                      {messageUsers.length === 0 ? (
                        <div className="no-users">No users found</div>
                      ) : (
                        <div className="message-users">
                          {messageUsers.map(user => (
                            <div 
                              key={user.id}
                              className={`message-user-item ${selectedMessageUser && selectedMessageUser.id === user.id ? 'active' : ''}`}
                              onClick={() => handleMessageUserSelect(user)}
                            >
                              <div className="user-avatar">
                                {user.avatar ? (
                                  <img src={user.avatar} alt={user.displayName || 'User'} />
                                ) : (
                                  <div className="avatar-placeholder">{(user.displayName || 'User').charAt(0)}</div>
                                )}
                              </div>
                              <div className="message-user-info">
                                <span className="message-user-name">{user.displayName || user.email || 'User'}</span>
                                <span className={`message-user-role role-${user.role}`}>{user.role}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  
                  <div className="messages-content">
                    {selectedMessageUser ? (
                      <>
                        <div className="messages-header">
                          <h4>Messages for {selectedMessageUser.displayName || selectedMessageUser.email || 'User'}</h4>
                          <button 
                            className="send-message-btn"
                            onClick={() => {
                              setShowUserMessageForm(true);
                              setUserMessageText('');
                              setUserMessageSubject('');
                              setUserMessageError('');
                              setEditMessage(null);
                              setSelectedUser(selectedMessageUser);
                            }}
                          >
                            Send New Message
                          </button>
                        </div>
                        
                        {userMessageError && <div className="message-error">{userMessageError}</div>}
                        {userMessageSuccess && <div className="message-success">{userMessageSuccess}</div>}
                        
                        {showUserMessageForm ? (
                          <div className="message-form">
                            <h4>{editMessage ? 'Edit Message' : 'Send Message'}</h4>
                            
                            <div className="form-group">
                              <label>Subject</label>
                              <input
                                type="text"
                                value={userMessageSubject}
                                onChange={(e) => setUserMessageSubject(e.target.value)}
                                className="profile-input"
                                placeholder="Enter message subject..."
                              />
                            </div>
                            
                            <div className="form-group">
                              <label>Message Content (HTML supported)</label>
                              <textarea
                                value={userMessageText}
                                onChange={(e) => setUserMessageText(e.target.value)}
                                className="profile-input"
                                rows={12}
                                placeholder="Enter message content (HTML supported)..."
                                style={{ minHeight: "300px", fontFamily: "monospace" }}
                              />
                            </div>
                            
                            <div className="message-actions">
                              <button
                                className="send-message-btn"
                                onClick={handleSendUserMessage}
                                disabled={sendingUserMessage}
                              >
                                {sendingUserMessage ? (
                                  <>
                                    {editMessage ? 'Updating...' : 'Sending...'} 
                                    <span className="loading-spinner-small"></span>
                                  </>
                                ) : (
                                  editMessage ? "Update Message" : "Send Message"
                                )}
                              </button>
                              <button
                                className="cancel-message-btn"
                                onClick={() => {
                                  setShowUserMessageForm(false);
                                  setUserMessageText('');
                                  setUserMessageSubject('');
                                  setUserMessageError('');
                                  setEditMessage(null);
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="all-messages-list">
                            {loadingMessages ? (
                              <div className="loading-messages">
                                <span className="loading-spinner-small"></span> Loading messages...
                              </div>
                            ) : allMessages.length === 0 ? (
                              <div className="no-messages">
                                No messages have been sent to this user.
                              </div>
                            ) : (
                              <div className="user-messages-list">
                                {allMessages
                                  .filter(message => messageFilter === 'all' || (messageFilter === 'pinned' && message.pinned))
                                  .sort((a, b) => {
                                    // First sort by pinned status
                                    if (a.pinned && !b.pinned) return -1;
                                    if (!a.pinned && b.pinned) return 1;
                                    // Then by date
                                    return new Date(b.createdAt) - new Date(a.createdAt);
                                  })
                                  .map((message) => (
                                  <div key={message.id} className={`user-message-item ${message.pinned ? 'pinned' : ''}`}>
                                    <div className="message-header">
                                      <div className="message-info">
                                        <span className="message-subject">{message.subject || 'No Subject'}</span>
                                        <span className="message-date">{formatDate(message.createdAt)}</span>
                                      </div>
                                      <div className="message-actions">
                                        <button 
                                          className={`icon-button pin-button ${message.pinned ? 'pinned' : ''}`} 
                                          title={message.pinned ? "Unpin Message" : "Pin Message"}
                                          onClick={() => handleTogglePin(message)}
                                          disabled={togglingPin}
                                        >
                                          {message.pinned ? '📌' : '📌'}
                                        </button>
                                        <button 
                                          className="icon-button" 
                                          title="Edit Message"
                                          onClick={() => {
                                            handleEditMessage(message);
                                            setSelectedUser(selectedMessageUser);
                                            setShowUserMessageForm(true);
                                          }}
                                        >
                                          ✏️
                                        </button>
                                        <button 
                                          className="icon-button delete" 
                                          title="Delete Message"
                                          onClick={() => handleDeleteMessage(message)}
                                        >
                                          🗑️
                                        </button>
                                      </div>
                                    </div>
                                    <div 
                                      className="message-content message-content-isolated"
                                    >
                                      <iframe 
                                        srcDoc={message.content} 
                                        src={createIframeSrc(message.content)}
                                        frameBorder="0" 
                                        scrolling="auto"
                                        title="Message content"
                                        className="content-iframe"
                                        style={{ 
                                          width: '100%', 
                                          minHeight: '50px',
                                          height: 'auto',
                                          border: 'none',
                                          padding: 0,
                                          margin: 0
                                        }}
                                        onLoad={(e) => {
                                          // Add message listener for iframe height updates
                                          const handleMessage = function(event) {
                                            if (event.data && event.data.type === 'resize') {
                                              // Add extra padding to prevent text cut-off
                                              e.target.style.height = (event.data.height + 0) + 'px';
                                            }
                                          };
                                          
                                          window.addEventListener('message', handleMessage);
                                          
                                          // Clean up event listener when component unmounts
                                          return () => {
                                            window.removeEventListener('message', handleMessage);
                                          };
                                        }}
                                      ></iframe>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="no-user-selected">
                        <p>Please select a user to view their messages</p>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        
        {activeTab === 'links' && (
          <div className="links-tab">
            <div className="links-container">
              {/* DO NOT REMOVE THESE LINKS! THEY ARE REQUIRED!
                  IMPORTANT: THESE TWO LINKS MUST ALWAYS BE PRESENT!
                  THESE LINKS WERE SPECIFICALLY REQUESTED BY USER! */}
              <div className="link-card">
                <a
                  href="https://discord.gg/SGMCCwsd"
                  className="link-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Join Discord
                </a>
              </div>
              
              <div className="link-card">
                <a
                  href="http://47.7.21.116:30000/join"
                  className="link-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Launch Foundry
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      
      {confirmAction && (
        <div className="confirm-modal">
          <div className="confirm-content">
            <h3>Confirm Action</h3>
            <p>{confirmAction.message}</p>
            <div className="confirm-buttons">
              <button 
                className="confirm-button"
                onClick={confirmAction.action}
              >
                {confirmAction.confirmText}
              </button>
              <button 
                className="cancel-button"
                onClick={() => setConfirmAction(null)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard; 